import React from 'react'
import { useTranslation } from 'react-i18next'
import { navigate } from 'gatsby'
import Heading from 'src/components/typography/Heading'
import { LightButton } from 'src/components/buttons/Primary'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'

export default function RegisterTeacher() {
    const { t } = useTranslation();
    const { registerTeacher } = useLocalizedRoutes();

    return (
        <div className="bg-baby-blue-600 py-16 md:py-24 mx-auto container">
            <div className="mx-auto w-4/5">
                <div className="lg:pr-24">
                    <Heading type='head-title-wrap' tagName="h2">
                        <span className="text-white">{t('howItWorks.passionateAbout')} </span>
                        <span className="text-egg-shell-600">{t('howItWorks.teachingMusic')}</span>
                    </Heading>
                    <p className="text-white text-lg md:text-xl w-full py-12">{t('howItWorks.passionateAboutDesc')}</p>
                </div>
                <div>
                    <LightButton
                        className="py-6"
                        onClick={() => navigate(registerTeacher)}
                    >
                        {t('howItWorks.registerATeacherAccount')}
                    </LightButton>
                </div>
            </div>
        </div>
    )
}
