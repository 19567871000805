import React from 'react'

export default function PlusCircle(props) {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props} cursor="pointer">
            <title>{"plus-circle"}</title>
            <path
                d="M12 .4C18.407.4 23.6 5.593 23.6 12S18.407 23.6 12 23.6.4 18.407.4 12 5.593.4 12 .4zm0 2.4a9.2 9.2 0 100 18.4 9.2 9.2 0 000-18.4zm0 3.6a1.2 1.2 0 011.195 1.084l.005.116-.001 3.2h3.121a1.2 1.2 0 01.116 2.395l-.116.005h-3.121l.001 3.2a1.2 1.2 0 01-2.395.116L10.8 16.4l-.001-3.2H7.68a1.2 1.2 0 01-.116-2.395l.116-.005h3.119l.001-3.2A1.2 1.2 0 0112 6.4z"
                fill="#24305E"
                fillRule="nonzero"
            />
        </svg>
    )
}
