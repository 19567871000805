import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { navigate } from 'gatsby'
import Heading from 'src/components/typography/Heading'
import FAQ from 'src/components/interactivity/FAQ'
import PrimaryButton from 'src/components/buttons/Primary'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'

export default function Accordion({ data, children, showMoreBtn }) {
    const [currOpen, setCurrOpen] = useState(null)
    const { t } = useTranslation();
    const { faqs } = useLocalizedRoutes();

    return (
        <div id="faqs" className="mx-auto px-4 py-16 md:py-20 bg-grey-100">
            <Heading type="sub-title" className="text-center" tagName="h3">{children}</Heading>
            <div className="md:w-8/12 mx-auto pt-12">
                {data.map((faq, index) => (
                    <FAQ key={index}
                         faq={faq}
                         currOpen={currOpen}
                         setCurrOpen={setCurrOpen}
                         isOpen={currOpen === index}
                         index={index}/>
                ))}
            </div>
            {showMoreBtn &&
                <div className="text-center pt-12">
                    <PrimaryButton onClick={() => navigate(faqs)}>
                        {t('howItWorks.moreQuestions')}
                    </PrimaryButton>
                </div>
            }
        </div>
    )
}
