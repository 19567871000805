import React from 'react'
import { useTranslation } from 'react-i18next'
import Heading from 'src/components/typography/Heading'
import { UnderlinedLink } from 'src/components/buttons/Link'
import ownPage from 'src/images/howitworks/img-ownpace-clean.png'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'
import video from 'src/video/howitworks-nl-2.mp4'

export default function OwnPace() {
    const { t } = useTranslation();
    const { findTeacher } = useLocalizedRoutes();

    return (
        <div className="px-4">
            <div className="container mx-auto my-12 lg:mb-24 lg:mt-0 flex flex-wrap flex-col md:flex-row-reverse justify-between items-center">
                <div className='w-full md:w-1/2 md:pr-4 xl:pr-0'>
                    <Heading type='head-title-wrap' tagName='h2'>
                        <span className='text-dark-blue-600 block'>{t('howItWorks.lessonsAdvert.ownPace.title')}</span>
                    </Heading>
                    <p className='text-lg md:text-xl mt-4'>{t('howItWorks.lessonsAdvert.ownPace.description')}</p>
                    <div className='text-xl mt-10'>
                        <UnderlinedLink className="text-grey-600" route={findTeacher}>
                            {t('home.findYourTeacher')}
                        </UnderlinedLink>
                    </div>
                </div>
                <div className="relative w-full md:w-5/12 mt-4">
                    <video
                        src={video}
                        autoPlay
                        muted
                        loop
                    />
                </div>
            </div>
        </div>
    )
}
