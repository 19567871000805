import React, {useEffect, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import PlusCircle from 'src/components/icons/PlusCircle'
import 'src/components/interactivity/FAQ.css'
import { Link } from 'gatsby'

export default function FAQ({faq, setCurrOpen, index, isOpen}) {
    const [height, setHeight] = useState('10em');
    const ref = React.createRef();
    const { t } = useTranslation();
    const baseUrl = process.env.API_BASE_URL

    function toggle() {
        if (isOpen) setCurrOpen(null)
        else setCurrOpen(index)
    }

    function pageLoad() {
        if (!(ref && ref.current && ref.current.childNodes[0])) {
            return
        }

        const h = ref.current.childNodes[0].getBoundingClientRect().height;
        setHeight(h ? `${h}px` : '10em');
    }

    useEffect(pageLoad, [])

    return (
        <div className={'faq' + (isOpen ? ' faq--open' : '')}>
            <div className="faq__question pb-4 flex justify-between items-center">
                <p className="text-dark-blue-600 font-bold md:font-extrabold md:text-lg cursor-pointer w-10/12 mt-3"
                   onClick={toggle}>
                    {faq.q}
                </p>
                <PlusCircle className="faq-icon" onClick={toggle}/>
            </div>
            <div
                ref={ref}
                className="faq__answer text-grey-600 overflow-scroll"
                style={{maxHeight: isOpen ? height : 0}}>
                <div>
                    <Trans
                        i18nKey={faq.a}
                        components={{italic: <i/>, bold: <strong/>}}
                    />
                    {faq.file &&
                    <Link to={`${baseUrl}/${faq.file}`} className="ml-2 text-xs underline text-zoom">{faq.file}</Link>
                    }
                </div>
            </div>
        </div>
    )
}
