import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Context as ThemeContext } from 'src/context/ThemeContext'
import MarketingLayout from 'src/components/layout/MarketingLayout'
import HowItWorksHero from 'src/components/sections/howItWorks/Hero'
import OnlineLessons from 'src/components/sections/howItWorks/OnlineLessons'
import OwnPace from 'src/components/sections/howItWorks/OwnPace'
import InstrumentHookUp from 'src/components/sections/home/InstrumentHookUp'
import RegisterTeacherBanner from 'src/components/sections/howItWorks/RegisterTeacher'
import Accordion from 'src/components/interactivity/Accordion'

export default function HowItWorks(props) {
    const { setTheme } = useContext(ThemeContext);
    const { t } = useTranslation();

    useEffect(() => setTheme('normal'), [])

    const faqsList = t('faqs.questions', { returnObject: true });

    return (
        <MarketingLayout {...props} title={t('howItWorks.title')}>
            <HowItWorksHero />
            <OnlineLessons />
            <OwnPace />
            <InstrumentHookUp />
            <RegisterTeacherBanner />
            <Accordion data={faqsList.splice(0, 4)} showMoreBtn>
                {t('faqs.questions&answers')}
            </Accordion>
        </MarketingLayout>
    )
}
