import React from 'react'
import { useTranslation } from 'react-i18next'
import Heading from 'src/components/typography/Heading'

export default function HowItWorksHero() {
    const { t } = useTranslation();

    return (
       <div className="px-4 bg-baby-blue-600 w-full py-12 md:pt-16 md:pb-40">
          <div className="container mx-auto text-center">
              <Heading type='head-title-wrap' tagName='h1'>
                  <span className="text-white block">{t('howItWorks.beginnerOrPro')}</span>
                  <span className="text-egg-shell-600">{t('howItWorks.somethingToLearn')}</span>
              </Heading>
          </div>
       </div>
    );
}
